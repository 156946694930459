<style lang="scss">
.modal-dialog {
  position: relative;
  width: auto;
  margin: 4.5rem auto;
}
#modal-shift-new___BV_modal_content_ {
  border-radius: 16px;
  position: relative;
  .top-icon {
    box-shadow: 0 1px 8px rgb(0 0 0 / 8%), 0 4px 24px rgb(28 36 43 / 16%);
    width: 64px;
    height: 64px;
    padding: 10px;
    border-radius: 16px;
    position: absolute;
    left: 24px;
    margin-top: -52px;

    background: white;
  }
  i {
    font-size: 12px;
  }
}

.row___1kxiG {
  box-sizing: border-box;
  margin-bottom: 24px;

  .daysSelectorContainer {
    margin-top: 30px;
    margin-bottom: 20px;
    .root {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .day {
        font-size: 20px;
        line-height: 25px;
        background: #dfe3e6;
        color: #555d61;
        cursor: pointer;
        font-weight: 500;
        padding: 10px;
        position: relative;
        width: 60px;
        z-index: 0;

        &:first-child {
          padding-left: 0;
          width: 54px;

          &:before {
            content: "";
            position: absolute;
            border-radius: 50%;
            background: #dfe3e6;
            width: 48px;
            height: 45px;
            z-index: -1;
            top: 0;
            left: -24px;
          }
        }
        &:last-child {
          padding-right: 0;
          width: 54px;
          &:after {
            content: "";
            position: absolute;
            border-radius: 50%;
            background: #dfe3e6;
            width: 48px;
            height: 45px;
            z-index: -1;
            top: 0;
            right: -24px;
          }
        }
      }

      .selected {
        background: #1bc5bd;
        color: #fff;
        &:last-child {
          &:after {
            background: #1bc5bd;
          }
        }
        &:first-child {
          &:before {
            background: #1bc5bd;
          }
        }
      }
    }
  }

  .daysSelectorTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      margin-top: -10px;
      margin-right: 10px;
    }
  }
}
</style>
<template>
  <b-modal
    hide-header
    hide-footer
    style="margin-top: 100px"
    ref="modal-shift-new"
    id="modal-shift-new"
    ok-title="Save"
    title="Add New Employee's Contract"
  >
    <div class="top-icon">
      <img
        style="margin-left: 5px; margin-top: 6px; width: 40px"
        src="media/svg/add_shift_ico.svg"
        alt=""
      />
    </div>
    <div style="margin-top: 40px">
      <h4>Create new shift</h4>
      <p>Create shift to set and manage employee's working days and hours.</p>
      <form @submit.stop.prevent="onSubmit">
        <b-form-group label="">
          <label for="">Shift Title <span class="text-danger">*</span> </label>
          <input
            :class="errors ? (errors['shift_title'] ? 'is-invalid' : '') : ''"
            type="text"
            class="form-control"
            v-model="formdata.shift_title"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{
              errors
                ? errors["shift_title"]
                  ? errors["shift_title"][0]
                  : ""
                : ""
            }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="">
          <label for="">Start Time <span class="text-danger">*</span> </label>
          <input
            @change="calculateTime()"
            @keyup="calculateTime()"
            :class="errors ? (errors['from_time'] ? 'is-invalid' : '') : ''"
            type="time"
            class="form-control"
            v-model="formdata.from_time"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{
              errors ? (errors["from_time"] ? errors["from_time"][0] : "") : ""
            }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="">
          <label for="">End Time <span class="text-danger">*</span> </label>
          <input
            @change="calculateTime()"
            @keyup="calculateTime()"
            :class="errors ? (errors['to_time'] ? 'is-invalid' : '') : ''"
            type="time"
            class="form-control"
            v-model="formdata.to_time"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ errors ? (errors["to_time"] ? errors["to_time"][0] : "") : "" }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="row___1kxiG">
          <div class="daysSelectorContainer">
            <div class="root">
              <div
                class="day"
                :class="formdata.weekend | hasWeekend('monday')"
                @click="selectWeekendDay('monday')"
              >
                Mon
              </div>
              <div
                class="day"
                :class="formdata.weekend | hasWeekend('tuesday')"
                @click="selectWeekendDay('tuesday')"
              >
                Tue
              </div>
              <div
                class="day"
                :class="formdata.weekend | hasWeekend('wednesday')"
                @click="selectWeekendDay('wednesday')"
              >
                Wed
              </div>
              <div
                class="day"
                :class="formdata.weekend | hasWeekend('thursday')"
                @click="selectWeekendDay('thursday')"
              >
                Thu
              </div>
              <div
                class="day"
                :class="formdata.weekend | hasWeekend('friday')"
                @click="selectWeekendDay('friday')"
              >
                Fri
              </div>
              <div
                class="day"
                :class="formdata.weekend | hasWeekend('saturday')"
                @click="selectWeekendDay('saturday')"
              >
                Sat
              </div>
              <div
                class="day"
                :class="formdata.weekend | hasWeekend('sunday')"
                @click="selectWeekendDay('sunday')"
              >
                Sun
              </div>
            </div>
          </div>
          <div class="daysSelectorTextContainer">
            <span class="text"
              >Adonis weekend {{ selectedWeekName.length }} days a week</span
            >
          </div>
          <div class="text-danger" v-if="errors['weekend']">
            {{ errors ? (errors["weekend"] ? errors["weekend"][0] : "") : "" }}
          </div>
        </div>

        <div class="form__field">
          <div class="form__label">
            <label for=""
              >Select Color <span class="text-danger">*</span>
            </label>
            <v-swatches v-model="formdata.color_code" inline></v-swatches>
            <div class="text-danger" v-if="errors['color_code']">
              {{
                errors
                  ? errors["color_code"]
                    ? errors["color_code"][0]
                    : ""
                  : ""
              }}
            </div>
          </div>
        </div>

        <div class="alert alert-primary" role="alert" v-if="showInfoDiff">
          {{
            selecteTimeDiffInHour + (selecteTimeDiffInHour > 1 ? "hrs" : "hr")
          }}
          {{
            selecteTimeDiffInMin + (selecteTimeDiffInMin > 1 ? "mins" : "min")
          }}
          working per day
        </div>

        <hr />

        <button
          class="btn btn-success btn-lg float-right"
          ref="kt_add_new_shift_btn"
        >
          <i class="flaticon2-add-1"></i>Save
        </button>
        <a
          class="btn btn-danger btn-lg float-right"
          @click="hideModal()"
          style="margin-right: 10px"
          ><i class="flaticon2-cancel-music"></i>Close</a
        >
      </form>
    </div>
  </b-modal>
</template>

<script>
import VSwatches from "vue-swatches";

// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css";
import { ADD_NEW_SHIFT_TYPE } from "@/core/services/store/actions.type";
// import {

// } from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      errors: [],
      selectedWeekName: [],
      selecteTimeDiffInHour: 0,
      selecteTimeDiffInMin: 0,
      showInfoDiff: false,

      formdata: {
        from_time: "",
        to_time: "",
        shift_title: "",
        weekend: "",
        color_code: ""
      }
    };
  },
  components: { VSwatches },
  computed: {},
  mounted() {
    this.clearForm();
  },
  filters: {
    hasWeekend(weekend, day) {
      return weekend.includes(day) ? "selected" : "";
    }
  },
  methods: {
    calculateTime() {
      const tFrom = this.formdata.from_time.split(":");
      const tTo = this.formdata.to_time.split(":");
      this.showInfoDiff = false;
      if (tTo.length > 1 && tFrom.length > 1) {
        this.showInfoDiff = true;
        const tFromHour = parseInt(tFrom[0]);
        const tFromMin = parseInt(tFrom[1]);
        const tToHour = parseInt(tTo[0]);
        const tToMin = parseInt(tTo[1]);
        if (tFromHour > tToHour) {
          this.toast(
            "danger",
            "Time Diff",
            true,
            "Please select end time is more then start time"
          );
        } else {
          this.selecteTimeDiffInHour = tToHour - tFromHour;
          this.selecteTimeDiffInMin =
            tToMin > tFromMin ? tToMin - tFromMin : tFromMin - tToMin;
        }
      }
    },
    selectWeekendDay(day) {
      this.formdata.weekend = "";
      if (this.selectedWeekName.includes(day)) {
        this.selectedWeekName.splice(this.selectedWeekName.indexOf(day), 1);
      } else {
        this.selectedWeekName.push(day);
      }
      this.selectedWeekName.forEach((element, index) => {
      
        this.formdata.weekend =
          this.formdata.weekend + (index > 0 ? "," : "") + element;
      });
    },
    clearForm() {
      this.selecteTimeDiffInMin = 0;
      this.selecteTimeDiffInHour = 0;
      this.selectedWeekName = [];
      this.formdata = {
        from_time: "",
        to_time: "",
        shift_title: "",
        weekend: "",
        color_code: ""
      };
    },
    toast(variant = null, title, append = false, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-center",
        variant: variant,
        solid: true,
        appendToast: append
      });
    },

    hideModal() {
      this.$refs["modal-shift-new"].hide();
      this.clearForm();
      this.errors = [];
    },
    onSubmit() {
      const formdata = this.formdata;

      // s spinner to submit button
      const submitButton = this.$refs["kt_add_new_shift_btn"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(ADD_NEW_SHIFT_TYPE, formdata)
        // go to which page after successfully login
        .then(response => {
        
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          this.toast("success", "Success!", true, response.message);
          this.errors = [];
          this.hideModal();
        })
        .catch(errors => {
          this.errors = errors.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          this.toast("danger", "Error!", true, errors.message);
        });
    }
  }
};
</script>
