<style lang="scss">
#shift {
  .card-body {
    text-align: center;
    padding: 1.25rem;
    position: relative;
  }
  .text-bold {
    font-weight: 500;
  }
  h1 {
    margin-bottom: 0;
  }

  ul > li {
    text-decoration: none;
  }

  .grid-list {
    margin-top: 20px;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;

    .shift-data-card {
      margin-bottom: 20px;

      .card {
        background: white;
        font-weight: bold;

        padding: 40px 20px;
        position: relative;
        display: flex;
        align-items: center;
        clear: both;

        .shift-title {
          position: absolute;
          width: 50%;
          color: black;
          display: flex;
          flex: 1;
          top: 0;
          align-items: center;
          left: 15px;
          margin: auto;
          bottom: 0;
          i {
            margin-right: 4px;
            font-size: 26px;
            color: #ccc;
          }
        }
        .emp-count {
          position: absolute;
          display: flex;
          flex: 1;
          align-items: center;
          color: #bbb !important;
          fill: #bbb !important;
          right: 15px;
          top: 0;
          bottom: 0;
          margin: auto;

          i {
            margin-right: 4px;
            font-size: 20px;
            color: #ccc;
          }
        }

        &:hover {
          box-shadow: 0 2px 5px 1px rgb(0 0 0 / 10%);
          transform: translateY(-2px);
          .shift-title {
            i {
              color: #1bc5bd;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div id="shift">
    <v-shift-add-modal></v-shift-add-modal>
    <v-shift-edit-modal :details="getShiftTypeEditDetail"></v-shift-edit-modal>
    <div class="card mt-0">
      <div class="card-body">
        <data-loader v-if="loader"  :padding="10" >
        </data-loader>
        <v-no-record-card
          v-if="getShiftTypes.length < 1 && !loader"
          :title="'Set up shifts'"
          :src="'media/svg/no_shift_record_found.svg'"
          :description="
            'There are no work shift created yet. Create shift to set and manage employees\' working days and hours.'
          "
        >
          <a class="btn btn-success" v-b-modal.modal-shift-new
            >Create first shift</a
          >
        </v-no-record-card>

        <div class="shift-header" v-if="getShiftTypes.length > 0 && !loader">
          <h1 class="title float-left">Shift</h1>
          <button
            v-b-modal.modal-shift-new
            class="btn btn-success btn-sm float-right"
          >
            <i class="flaticon-time-3"></i>
            <span class="text-bold">Create shift</span>
          </button>
        </div>
      </div>
    </div>

    <ul class="row grid-list" v-if="getShiftTypes.length > 0 && !loader">
      <li
        class="col-lg-4 shift-data-card"
        v-for="item in getShiftTypes"
        :key="item.id"
      >
        <a
          class="card btn"
          v-b-modal.modal-shift-edit
          @click="fetchShiftTypeEditDetail(item.id)"
          :style="
            'border-top: 5px solid' +
              item.color_code +
              '; background:' +
              (item.isNew ? '#f0feff' : '')
          "
        >
          <div class="tag"></div>
          <p class="shift-title">
            <i class="flaticon-calendar-with-a-clock-time-tools"></i>
            {{ item.title }}
          </p>
          <div class="emp-count">
            <i class="flaticon-users"> </i>{{ item.total_assigned_employee }}
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import NoRecordFoundCard from "@/includes/content/NoRecordFoundCard";
import ShiftAddModal from "@/pages/admin/shift/default/add.vue";
import ShiftEditModal from "@/pages/admin/shift/default/edit.vue";
import { mapGetters } from "vuex";
import {
  FETCH_SHIFT_TYPES,
  FETCH_SHIFT_TYPES_EDIT_DETAIL
} from "@/core/services/store/actions.type";
import dataLoader from "@/includes/content/DataLoader";
// import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loader: false
    };
  },
  computed: {
    ...mapGetters(["getShiftTypes", "getShiftTypeEditDetail"])
  },
  components: {
    "v-no-record-card": NoRecordFoundCard,
    "v-shift-add-modal": ShiftAddModal,
    "v-shift-edit-modal": ShiftEditModal,
    "data-loader": dataLoader 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Shifts" }]);
    this.fetchShiftsType();
  },
  methods: {
    fetchShiftTypeEditDetail(id) {
      this.$store.dispatch(FETCH_SHIFT_TYPES_EDIT_DETAIL, id);
    },
    fetchShiftsType() {
      this.loader = true;
      this.$store
        .dispatch(FETCH_SHIFT_TYPES)
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
</script>
