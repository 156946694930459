<style lang="scss">
.content-box {
  background-color: #fff;
  border-width: 1px 0;
  padding: 0;
  position: relative;
  text-align: center;
  img {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
</style>
<template>
  <article class="content-box p-6">
    <h4>{{ title }}</h4>
    <p>{{ description }}</p>
    <img :src="src" alt="" /><br />
    <slot></slot>
  </article>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "media/svg/contract_index_add.svg"
    },
    title: {
      type: String,
      default: "Title"
    },
    description: {
      type: String,
      default: "Description"
    }
  }
};
</script>
