<template>
  <div :class="'p-' + padding">
    <div
      class="spinner-border"
      :style="newstyle"
      :class="'text-' + type"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary"
    },
    newstyle: {
      type: String,
      default: ""
    },
    padding: {
      type: Number,
      default: 2
    }
  }
};
</script>
